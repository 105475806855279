import jwt_decode from "jwt-decode";

const apiroot = "/api";

export default class {
    static get isAuthenticated() {
        if(!this.initialized) {
            this.jwt = localStorage.getItem('jwt');
            this.hostname = window.location.hostname;
        }
        return !!this.jwt;
    }
    static get username() {
        if(this.isAuthenticated) {
            return jwt_decode(this.jwt)['user_name'];
        }
        return "";
    }
    static get roles() {
        if(this.isAuthenticated) {
            return jwt_decode(this.jwt)['authorities'] ? jwt_decode(this.jwt)['authorities'] : [];
        }
        return [];
    }


    static hostname = "";
    static initialized = false;
    static jwt = "";
    static oauth_username = "cami-jwt-client";
    static oauth_secret = "Wvcebt5sQVkmsXzU";

    static login(username, password) {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('grant_type', 'password');
        return fetch(`${apiroot}/oauth/token`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'Basic ' + btoa(`${this.oauth_username}:${this.oauth_secret}`),
            },
        }).then(result=>result.json())
            .then(result=> {
                this.jwt = result['access_token'];
                if(this.jwt) {
                    localStorage.setItem('jwt', this.jwt);
                }
                return !!this.jwt;
            });
    }

    static register(companyCode, username, password, name, employeeId, birthDate, gender) {
        return this.requestPost("user/register", {companyCode, username, password, name, employeeId, birthDate, gender});
    }

    static logout() {
        this.jwt = "";
        localStorage.removeItem('jwt');
    }

    static requestPost(path, data) {
        return fetch(`${apiroot}/${path}`, {
          body: JSON.stringify(data),
          method: 'POST',
          headers: {
            'Authorization': this.jwt ? `Bearer ${this.jwt}` : undefined,
            'content-type': 'application/json'
          }
        }).then(result=>result.json());
    }


    static requestGet(path) {
        let res = fetch(`${apiroot}/${path}`, {
            headers: {
              'Authorization': this.jwt ? `Bearer ${this.jwt}` : undefined
            }
        }).then(result=>result.json());
        console.log(path, res);
        return res;
    }
}
