import api from '.';

export default class {
    // getRun(runId) {
    //     return api.requestGet(`run?runId=${runId}`)
    //         .then(ret=> {
    //             ret.openTime = moment(ret.openTime * 1000);
    //             ret.closeTime = moment(ret.closeTime * 1000);
    //             return ret;
    //         });
    // }
    //
    // getResults(runId) {
    //     return api.requestGet(`run/results?runId=${runId}`);
    // }

    getSession() {
        return api.requestGet(`response/session`);
    }
    getJobRoles() {
        return api.requestGet(`response/job_roles`);
    }
    setJobRole(sessionId, roleId) {
        return api.requestGet(`response/setRole?sessionId=${sessionId}&roleId=${roleId}`);
    }
    getQuestion(sessionId, id) {
        return api.requestGet(`response/getQuestion?sessionId=${sessionId}&id=${id}`);
    }
    saveResponse(sessionId, id, ans) {
        return api.requestGet(`response/saveResponse?sessionId=${sessionId}&id=${id}&ans=${ans}`);
    }
}
