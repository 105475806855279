import React, { Component } from 'react';

export default class SafetyAndHealth extends Component {
    render() {
        return (
            <div>
                Safety and health
            </div>
        );
    }
}
