import React, { Component } from 'react';

export default class AboutCompany extends Component {
    render() {
        return (
            <div>
                <h1>About CAMi</h1>
                <p> CAMi is a provider of cloud-based services for construction and property management. CAMi helps hedge and manage risk, measure and improve culture, and protect and promote the health and safety of all through the use of an advanced analytics platform. </p>
            </div>
        );
    }
}
