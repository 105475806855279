import React, { Component } from 'react';
import { scaleLinear, scaleBand } from 'd3-scale';
import { axisLeft, axisBottom } from 'd3-axis';
import { select } from 'd3-selection';

import './BarChart.css';

export default class extends Component {

    componentDidMount() {
        this.createBarChart();
    }

    handleMouseOver() {
        select(this)
            .attr('class', 'bar-highlighted');
    }

    handleMouseOut() {
        select(this)
            .attr('class', 'bar');
    }

    createBarChart() {
        const node = this.node;
        const { data, height, width, color } = this.props;

        const maxVal = Math.max(...data.map(d => d.whiskerRange[1]));
        const yScale = scaleLinear()
            .domain([0, maxVal])
            .range([height - 25, 20]);
        const xScale = scaleBand()
            .domain(data.map(d => d.jobRole))
            .range([40, width])
            .padding(0.2);

        const xAxis = axisBottom(xScale);
        const yAxis = axisLeft(yScale).ticks(5);

        select(node).append('g')
            .attr('transform', `translate(0, ${height - 20})`)
            .call(xAxis);
        select(node).append('g')
            .attr('transform', 'translate(40, 0)')
            .call(yAxis);

        const barGroup = select(node).append('g')
            .selectAll('.bar')
            .data(data).enter()
            .append('g');

        barGroup.append('rect')
            .attr('x', d => xScale(d.jobRole))
            .attr('y', d => yScale(d.value))
            .attr('width', xScale.bandwidth())
            .attr('height', d => yScale(0) - yScale(d.value))
            .attr('fill', color)
            .attr('stroke', color)
            .attr('class', 'bar')
            .on('mouseover', this.handleMouseOver)
            .on('mouseout', this.handleMouseOut);

        barGroup.append('title')
            .text(d => `${d.jobRole}: ${Number(d.value).toFixed(1)}`);

        const whiskerGroup = barGroup.append('g')
            .attr('class', 'whiskerGroup');

        // Lower horizontal line
        whiskerGroup.append('line')
            .attr('y1', d => yScale(d.whiskerRange[0]))
            .attr('y2', d => yScale(d.whiskerRange[0]))
            .attr('x1', d => xScale(d.jobRole) + 0.5 * xScale.bandwidth() - 10)
            .attr('x2', d => xScale(d.jobRole) + 0.5 * xScale.bandwidth() + 10)
            .attr('class', 'whisker');

        // Upper horizontal line
        whiskerGroup.append('line')
            .attr('y1', d => yScale(d.whiskerRange[1]))
            .attr('y2', d => yScale(d.whiskerRange[1]))
            .attr('x1', d => xScale(d.jobRole) + 0.5 * xScale.bandwidth() - 10)
            .attr('x2', d => xScale(d.jobRole) + 0.5 * xScale.bandwidth() + 10)
            .attr('class', 'whisker');

        // Vertical line
        whiskerGroup.append('line')
            .attr('y1', d => yScale(d.whiskerRange[0]))
            .attr('y2', d => yScale(d.whiskerRange[1]))
            .attr('x1', d => xScale(d.jobRole) + 0.5 * xScale.bandwidth())
            .attr('x2', d => xScale(d.jobRole) + 0.5 * xScale.bandwidth())
            .attr('class', 'whisker');
    }

    render() {
        return (
            <svg width={this.props.width} height={this.props.height} ref={node => this.node = node}>
            </svg>
        );
    }

}
