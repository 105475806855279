import React, { Component } from 'react';

export default class OnlineTrainings extends Component {
    render() {
        return (
            <div>
                Online Trainings
            </div>
        );
    }
}
