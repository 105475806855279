import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Alert, Glyphicon } from 'react-bootstrap';

import api from '../api';
import UserApi from '../api/User';

import './Register.css';

class Register extends Component {
  constructor(props) {
    super(props);
    document.addEventListener('click', (event) => {
      if (!event.target.classList.contains('dropdown-item') && event.target.id !== 'name-input') {
        const dropdown = document.getElementById('name-dropdown');
        if (dropdown && dropdown.classList.contains('show')) {
          dropdown.classList.remove('show');
        }
      }
    });
  }

  state = {
    redirectToReferrer: false,
    companyCode: '',
    password: '',
    confirmPassword: '',
    name: '',
    employeeId: '',
    birthDate: '2019-1-1',
    gender: '',
    companyNames: [],
    error: false,
    errorText: ''
  };

  register = (e) => {
    if(this.state.password !== this.state.confirmPassword) {
      this.setState({ error: true, errorText: 'Passwords do not match' });

      e.preventDefault();
      return;
    }
    api.register(this.state.companyCode, this.state.username, this.state.password, this.state.name, this.state.employeeId, new Date(this.state.birthDate), this.state.gender).then((s) => {
      console.log(s);
      if(s && (!s.status || s.status === 200) && s) {
        this.setState({ redirectToReferrer: true, error: false });
      } else {
        this.setState({ error: true, errorText: 'Validation information incorrect, please see a supervisor for help' });
      }
    });
    e.preventDefault();
  };

  lookupCompanyCode = (e) => {
    new UserApi().getNamesForCompany(this.state.companyCode).then(s => {
      if (s.status && s.status === 500) {
        this.setState({ error: true, errorText: 'Bad company code' });
      } else {
        this.setState({error: false});
        this.setState({companyNames: s});
      }
    });
    e.preventDefault();
  };

  renderError = () => {
    return this.state.error ? <Alert bsStyle={'danger'}>
      <Glyphicon glyph="exclamation-sign" />
      {this.state.errorText}
    </Alert> : null;
  };

  namesToShow = () => {
    return this.state.companyNames.filter(name => {
      return name.slice(0, this.state.name.length) === this.state.name;
    });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div style={{'width': '40%', 'margin': '0 auto'}}>
        <form>
          {this.renderError()}
          <div className={'form-group'}>
            <label htmlFor={'company-code-input'}>Company Code</label>
            <input
                id={'company-code-input'}
                className={'form-control'}
                type="text"
                value={this.state.companyCode}
                placeholder="Company code"
                onChange={event => this.setState({companyCode: event.target.value})}
            />
          </div>
          {this.state.companyNames.length > 0 ? (
              <div>
                <div className={'form-group'}>
                  <label htmlFor={'name-input'}>Name</label>
                  <input
                      id={'name-input'}
                      className={'form-control'}
                      type="text"
                      value={this.state.name}
                      placeholder="Name"
                      onChange={event => this.setState({name: event.target.value})}
                      onFocus={() => document.getElementById('name-dropdown').classList.add('show')}
                  />
                  <div id={'name-dropdown'} className={'dropdown-content'}>
                    {this.namesToShow().map(theName =>
                        <p
                            className={'dropdown-item'}
                            onClick={() => {
                              this.setState({name: theName});
                            }}
                            key={theName}
                        >{theName}</p>
                    )}
                  </div>
                </div>
                <div className={'form-group'}>
                  <label htmlFor={'password-input'}>Password</label>
                  <input
                      id={'password-input'}
                      className={'form-control'}
                      type="password"
                      value={this.state.password}
                      placeholder="Password"
                      onChange={event => this.setState({password: event.target.value})}
                  />
                  <input
                      id={'password-input-confirm'}
                      className={'form-control'}
                      type="password"
                      value={this.state.passwordConfirm}
                      placeholder="Confirm Password"
                      onChange={event => this.setState({confirmPassword: event.target.value})}
                  />
                </div>
                <div className={'form-group'}>
                  <label htmlFor={'id-input'}>Employee ID</label>
                  <input
                      id={'id-input'}
                      className={'form-control'}
                      type="text"
                      value={this.state.employeeId}
                      placeholder="Employee Id"
                      onChange={event => this.setState({employeeId: event.target.value})}
                  />
                </div>
                <div className={'form-group'}>
                  <label htmlFor={'birthdate-input'}>Birthdate</label>
                  <input
                      id={'birthdate-input'}
                      className={'form-control'}
                      type={'date'}
                      value={this.state.birthDate}
                      onChange={event => this.setState({birthDate: event.target.value})}
                  />
                </div>
                <div className={'form-group'}>
                  <label htmlFor={'gender-input'}>Gender</label>
                  <input
                      id={'gender-input'}
                      className={'form-control'}
                      type={'text'}
                      value={this.state.gender}
                      placeholder={'Gender'}
                      onChange={event => this.setState({gender: event.target.value})}
                  />
                </div>
                <Button type="submit" onClick={this.register}>Submit</Button>
            </div>

          ) : <div><Button type={'submit'} onClick={this.lookupCompanyCode}>Lookup Company</Button></div>}
        </form>
      </div>
    );
  }
}

export default Register;
