import React, { Component } from 'react';

export default class Culture extends Component {
    render() {
        return (
            <div>
                Culture
            </div>
        );
    }
}
