import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';

export default class Products extends Component {
    render() {
        return (
            <Jumbotron>
                <h1>Solutions for your business</h1>
                <p>We pride ourselves on finding the right solutions for your business.</p>
            </Jumbotron>
        );
    }
}
