import React, { Component } from 'react';
import { Alert, Glyphicon, Button } from 'react-bootstrap';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

import ResponseApi from '../api/Response';

class RunResults extends Component {
    constructor(props) {
        super(props);
        this.state = {session: {}, jobRoles: [], response: {}, idx: 0, unauthorized: false, sliderValue: 0};
        this.responseApi = new ResponseApi();
        this.responseApi.getSession()
            .then(session => {
                if(session['error'] === 'access_denied') {
                    this.setState({unauthorized: true});
                    return;
                } else if(session['error']) {
                    console.log("Other error (TODO)");
                    return;
                }
                this.setState({session});
                this.getNextQuestion();
            });
        this.responseApi.getJobRoles()
            .then(jobRoles => {
                this.setState({jobRoles});
            });

    }

    getNextQuestion = () => {
        this.responseApi.getQuestion(this.state.session.id, this.state.idx)
            .then(response => {
                //TODO validation
                if(response.data) {
                    this.setState({idx: this.state.idx + 1});
                    this.getNextQuestion();
                } else if(this.state.idx < this.state.session.numQuestions) {
                    this.setState({response, sliderValue: 0});
                } else {
                    this.setState({response: {}, sliderValue: 0});
                }
            });
    };

    selectRole = (id) => {
        this.responseApi.setJobRole(this.state.session.id, id)
            .then((session) => this.setState({session}));
    };

    submitAnswer = (ans) => {
        this.responseApi.saveResponse(this.state.session.id, this.state.idx, ans)
            .then((question) => {
                //TODO validation
                this.setState({question, idx: this.state.idx + 1});
                this.getNextQuestion();
            });
    };

    renderRoleSelector = () => {
        if (this.state.session.role === null) {
            return <div>
                Select your role:
                {this.state.jobRoles ?
                    this.state.jobRoles.map((role, idx) =>
                    <Button block bsSize="large" key={idx} onClick={() => this.selectRole(role.id)}>
                        {role.name}
                    </Button>)
                : undefined}
            </div>
        }
        return undefined;
    };

    renderMultipleChoice = () => {
        const options = this.state.response.question.data.options;
        //Adapted from https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4
        const shuffledOptions = options.map((a) => [Math.random(),a]).sort((a,b) => a[0]-b[0]).map((a) => a[1]);
        return [<p key={-1}>{this.state.response.question.text}</p>,
            shuffledOptions.map((ans, idx) =>
            <Button block bsSize="large" key={idx} onClick={() => this.submitAnswer(idx)}>
                {ans.name}
            </Button>)];
    };

    handleChange = value => {
        this.setState({
            sliderValue: value
        })
    };

    renderSlider = () => {
        const data = this.state.response.question.data;
        return [<p key={-1}>{this.state.response.question.text}</p>,
            <div className='slider' style={{padding: '10px'}}>
            <Slider key={1}
                min={data.min}
                max={data.max}
                value={this.state.sliderValue}
                onChange={this.handleChange}
                labels={{[data.min]: data.minLabel, [data.max]: data.maxLabel}}/>
            </div>,
            <div className='value'>{this.state.sliderValue}</div>,
            <Button block bsSize="large" key={2} onClick={() => this.submitAnswer(this.state.sliderValue)}>
                Submit
            </Button>
            ];
    };

    renderQuestionSelector = () => {
        if (this.state.session.role && this.state.response.question) {
            switch(this.state.response.question.questionType) {
                case 'MultipleChoice':
                    return this.renderMultipleChoice();
                case 'Slider':
                    return this.renderSlider();
                default:
                    return <div>{this.state.response.question.questionType}</div>
            }
        }
        return undefined;
    };

    render() {
        return (
            <div>
                <div>
                    { this.state.unauthorized &&
                    <Alert bsStyle={'danger'}>
                        <Glyphicon glyph="exclamation-sign" />
                        You do not have permission to take surveys
                    </Alert>
                    }

                    { this.renderRoleSelector() }

                    { this.renderQuestionSelector() }

                    { this.state.idx === this.state.session.numQuestions ?
                    <Alert bsStyle={'info'}>
                        {/*<Glyphicon glyph="exclamation-sign" />*/}
                        You have finished taking the current survey
                    </Alert>
                    : undefined}
                </div>
            </div>
        );
    }
}

export default RunResults;
