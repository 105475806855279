import React, {Component} from 'react';
import moment from 'moment';
import {Grid, Row, Col, Alert, Glyphicon, FormGroup, ControlLabel, FormControl, Panel} from 'react-bootstrap';

import RunApi from '../api/Run';
import ProgressCircle from "./ProgressCircle";
import BarChart from './BarChart';

import "./RunResults.css"


class RunResults extends Component {
  constructor(props) {
    super(props);
    this.state = {runs: [], results: {}, run: {}, unauthorized: false};
    new RunApi().getRuns()
      .then(runs => {
        if (runs['error'] === 'access_denied') {
          this.setState({unauthorized: true});
          return;
        } else if (runs['error']) {
          console.log("Other error (TODO)");
          return;
        }
        this.setState({runs});
      });
  }

  handleChange = () => {
    const selectedRun = this.runSelector.value;
    if (!selectedRun) {
      this.setState({results: {}, run: {}});
      return;
    }

    new RunApi().getRun(selectedRun)
      .then(run => {
        if (run['error'] === 'access_denied') {
          this.setState({unauthorized: true});
          return;
        } else if (run['error']) {
          console.log("Other error (TODO)");
          return;
        }
        this.setState({run});
        console.log(this.state);
      });
    new RunApi().getResults(selectedRun)
      .then(results => {
        this.setState({results});
        console.log(this.state);
      });
  };

  renderRunSelector = () => {
    if (this.state.runs.length === 0) return undefined;
    return <FormGroup controlId="runResultsSelect">
      <ControlLabel>Run Results</ControlLabel>
      <FormControl componentClass="select"
                   inputRef={node => this.runSelector = node}
                   onChange={this.handleChange}>
        <option value="">Select a run</option>
        {this.state.runs.map((value, i) =>
          <option key={i} value={value['id']}>{value['name']}</option>
        )}
      </FormControl>
    </FormGroup>;
  };

  renderProgress = (name, value, labels, categoryData, maxCategoryScore, jobRoleScoreStdDevs) => {
    const chartOptions = {};

    let category = this.state.results.categories.find((x) => x.name === name);
    category = category ? category : {displayColor: "#ff7900"};

    const chartData = labels.map(roleKey => {
      return {
        jobRole: roleKey,
        value: categoryData[roleKey],
        whiskerRange: [categoryData[roleKey] - jobRoleScoreStdDevs[roleKey], categoryData[roleKey] + jobRoleScoreStdDevs[roleKey]]
      };
    });

    return <div>
      <p key={1}>{name} Score:</p>
      <ProgressCircle key={2}
                      label="Score"
                      formatValueText={function (valueNow, valueMax) {
                        return valueNow + ' out of ' + valueMax
                      }}
                      formatDisplayedValue={function (valueNow, valueMax) {
                        return (
                          <span style={{fontWeight: "bold", color: category.displayColor}}>
                      {Number((valueNow / valueMax) * 100).toFixed(0)}%
                    </span>
                        )
                      }}
                      variant="circle"
                      valueMax={maxCategoryScore}
                      valueNow={value}
                      radius={30}
                      color={category.displayColor}
      /><br/>
      <BarChart data={chartData} options={chartOptions} color={category.displayColor} width="300" height="300"/>
    </div>
  };

  renderStatistics = (pValue) => {
    if(pValue < 0) {
      return <span>Not enough results available at this time for statistical testing</span>
    } else if(pValue < 0.05) {
      return <span><b>Groups of workers have meaningfully different scores (p-value: {pValue.toFixed(2)})</b></span>
    } else {
      return <span>Groups of workers do not have meaningfully different scores (p-value: {pValue.toFixed(2)})</span>
    }
  };

  pValToStars = (pValue) => {
    let text = '';
    if (pValue > 0.10) {
      text = '';
    } else if (pValue <= 0.10 && pValue > 0.05) {
      text = '*';
    } else if (pValue <= 0.05 && pValue > 0.01) {
      text = '**';
    } else if (pValue <= 0.01 && pValue > 0) {
      text = '***';
    }
    return <span title={`The p-value is ${pValue}`}>{text}</span>;
  };

  renderTotalProgress = (name, value, labels, categoryData, maxSubmissionScore, jobRoleStdDevs, pairwisePVals, maxCategoryScores) => {
    let category = this.state.results.categories.find((x) => x.name === name);
    category = category ? category : {displayColor: "#ff7900"};

    const categoryRoleScores = this.state.results.avgCategoryRoleScores;
    const totalByRole = Object.keys(categoryRoleScores).reduce((acc, categoryKey) => {
      Object.keys(categoryRoleScores[categoryKey]).forEach(jobRoleKey => {
        if (!acc.hasOwnProperty(jobRoleKey)) {
          acc[jobRoleKey] = 0;
        }
        acc[jobRoleKey] += categoryRoleScores[categoryKey][jobRoleKey];
      });

      return acc;
    }, {});

    const barChartData = labels.map(roleKey => {
      return {
        jobRole: roleKey,
        value: totalByRole[roleKey],
        whiskerRange: [totalByRole[roleKey] - jobRoleStdDevs[roleKey], totalByRole[roleKey] + jobRoleStdDevs[roleKey]]
      };
    });

    const proportionCategoryScores = Object.keys(this.state.results.avgCategoryScores).reduce((acc, key) => {
      acc[key] = this.state.results.avgCategoryScores[key] / maxCategoryScores[key];
      return acc;
    }, {});

    const categoryKeysSorted = Object.keys(proportionCategoryScores).sort(function (a, b) {
      return proportionCategoryScores[b] - proportionCategoryScores[a]
    });

    const jobRoles = Object.keys(jobRoleStdDevs);

    const pValFormatted = pVal => {
      return pVal.toFixed(4);
    };

    return <Panel>
      <Panel.Heading>
        <Panel.Title componentClass="h3">{name} Results</Panel.Title>
      </Panel.Heading>
      <Panel.Body className="container-fluid"><Grid className="text-left">
        <Row className="show-grid">
          <Col md={2}>
            <ProgressCircle key={2}
                            label="Score"
                            formatValueText={function (valueNow, valueMax) {
                              return valueNow + ' out of ' + valueMax
                            }}
                            formatDisplayedValue={function (valueNow, valueMax) {
                              return (
                                <span style={{fontWeight: "bold", color: category.displayColor}}>
                          {Number((valueNow / valueMax) * 100).toFixed(0)}%
                        </span>
                              )
                            }}
                            variant="circle"
                            valueMax={maxSubmissionScore}
                            valueNow={value}
                            radius={50}
                            color={category.displayColor}
            />
          </Col>
          <Col md={4}>
            <BarChart data={barChartData} width="300" height="300" color={'#ff7900'}/>
            {this.renderStatistics(this.state.results.pvalue)}
          </Col>
          <Col md={4}>
            <table className="table table-condensed">
              <thead>
              <tr><td className="caption">Best Categories</td></tr>
              <tr>
                <th className="noTopBorder">Category</th>
                <th className="noTopBorder">Score out of max</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{categoryKeysSorted[0]}</td>
                <td>{proportionCategoryScores[categoryKeysSorted[0]].toFixed(2)}</td>
              </tr>
              <tr>
                <td className="noTopBorder">{categoryKeysSorted[1]}</td>
                <td className="noTopBorder">{proportionCategoryScores[categoryKeysSorted[1]].toFixed(2)}</td>
              </tr>
              </tbody>
              <thead>
              <tr><td className="caption noTopBorder">Worst Categories</td></tr>
              <tr>
                <th className="noTopBorder">Category</th>
                <th className="noTopBorder">Score out of max</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{categoryKeysSorted.slice(-1)[0]}</td>
                <td>{proportionCategoryScores[categoryKeysSorted.slice(-1)[0]].toFixed(2)}</td>
              </tr>
              <tr>
                <td className="noTopBorder">{categoryKeysSorted.slice(-2)[0]}</td>
                <td className="noTopBorder">{proportionCategoryScores[categoryKeysSorted.slice(-2)[0]].toFixed(2)}</td>
              </tr>
              </tbody>
            </table>
          </Col>
          <Col md={2}/>
        </Row>
        <Row>
          <Col xs={12}>
            <table className={'table'}>
              <thead>
                <tr>
                  <th>Job Role</th>
                  {
                    jobRoles.map(role => <th key={role + 'header'}>{role}</th>)
                  }
                </tr>
              </thead>
              <tbody>
              {
                jobRoles.map(role1 => (
                    <tr key={`${role1}row`}>
                      <td key={`${role1}rowLabel`}>{role1}</td>
                      {jobRoles.map(role2 => (
                        <td key={`${role1}${role2}`}>
                          {pValFormatted(pairwisePVals[role1][role2])}{this.pValToStars(pairwisePVals[role1][role2])}
                        </td>)
                      )}
                    </tr>
                ))
              }
              </tbody>
            </table>
          </Col>
        </Row>
      </Grid>
      </Panel.Body>

    </Panel>
  };

  renderNotableEmployees = (topSubmissionScores, bottomSubmissionScores, submissionScorePercentiles) => {
    const sortedPercentileKeys = Object.keys(submissionScorePercentiles).sort();

    return (<Panel id="collapsible-panel-example-2">
      <Panel.Heading>
        <Panel.Title toggle>
          Employee Statistics
        </Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          <Grid>
            <Row>
              <Col md={6} sm={12}>
                <table className="table">
                  <caption>Top-rating Employees</caption>
                  <thead>
                  <tr>
                    <th>Score</th>
                  </tr>
                  </thead>
                  <tbody>
                  {topSubmissionScores.map((x) =>
                      (<tr key={x}>
                        <td>{Number(x).toFixed(1)}</td>
                      </tr>))
                  }
                  </tbody>
                </table>


                <table className="table">
                  <caption>Bottom-rating Employees</caption>
                  <thead>
                  <tr>
                    <th>Score</th>
                  </tr>
                  </thead>
                  <tbody>
                  {bottomSubmissionScores.map((x) =>
                      (<tr key={x}>
                        <td>{Number(x).toFixed(1)}</td>
                      </tr>))
                  }
                  </tbody>
                </table>
              </Col>
              <Col md={6} sm={12}>
                <table className={'table'}>
                  <caption>Submission score percentiles</caption>
                  <thead>
                    <tr>
                      <th>Percentile</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    sortedPercentileKeys.map(k => (
                        <tr key={k}>
                          <td>{k}</td>
                          <td>{submissionScorePercentiles[k]}</td>
                        </tr>
                    ))
                  }
                  </tbody>
                </table>
              </Col>
            </Row>
          </Grid>
        </Panel.Body>
      </Panel.Collapse>
    </Panel>);
  };

  render() {
    return (
      <div style={{paddingBottom: '5em'}}>
        <div>
          {this.state.unauthorized &&
          <Alert bsStyle={'danger'}>
            <Glyphicon glyph="exclamation-sign"/>
            You do not have permission to view run results. <br/>
          </Alert>
          }

          {this.renderRunSelector()}

          <div className="">
            {this.state.results.avgSubmissionScore && this.renderTotalProgress("Total", this.state.results.avgSubmissionScore, this.state.results.roles, this.state.results.avgRoleScores, this.state.results.maxSubmissionScore, this.state.results.roleScoreStdDevs, this.state.results.pairwisePValues, this.state.results.maxCategoryScores)}

            {this.state.results.rejectedSubmissions &&
            <Panel id="collapsible-panel-example-2">
              <Panel.Heading>
                <Panel.Title toggle>
                  Rejected Submissions ({this.state.results.rejectedSubmissions.length})
                </Panel.Title>
              </Panel.Heading>
              <Panel.Collapse>
                <Panel.Body>
                  <table className="table">
                    <thead>
                    <tr>
                      <th>Employee Id</th>
                      <th>Employee Name</th>
                      <th>Employee Username</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.results.rejectedSubmissions.map((x) =>
                      (<tr key={x.employeeId.toString()}>
                        <td>{x.employeeId}</td>
                        <td>{x.name}</td>
                        <td>{x.username}</td>
                      </tr>))
                    }
                    </tbody>
                  </table>
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
            }

            {this.state.results.topSubmissionScores && this.state.results.bottomSubmissionScores && this.state.results.submissionScorePercentiles &&
            this.renderNotableEmployees(this.state.results.topSubmissionScores, this.state.results.bottomSubmissionScores, this.state.results.submissionScorePercentiles)
            }

            <Grid>
              <Row className="show-grid">
                {this.state.results.avgCategoryScores && Object.entries(this.state.results.avgCategoryScores)
                  .sort(function (a, b) {
                    return a[0] > b[0]
                  })
                  .map((value, i) => {
                    return <Col md={3} key={i} style={{display: "inline-block", paddingBottom: "2em"}}>
                      {this.renderProgress(value[0], value[1], this.state.results.roles, this.state.results.avgCategoryRoleScores[value[0]], this.state.results.maxCategoryScores[value[0]], this.state.results.categoryRoleScoreStdDevs[value[0]])}
                    </Col>})}
              </Row>
            </Grid>
          </div>

          {this.state.run.closeTime && moment().isBefore(this.state.run.closeTime) &&
          <div style={{paddingTop: "3em"}}>
            <Alert bsStyle={'warning'}>
            <Glyphicon glyph="warning-sign"/>
              <span> </span>
            The run "{this.state.run.name}" ends at {this.state.run.closeTime.format("dddd, MMMM Do YYYY, h:mm a")}.
            Results may not be complete until that time. <br/>
          </Alert>
          </div>
          }
        </div>
      </div>
    );
  }
}

export default RunResults;
