import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className={'fluid-container'}>
                    <ul className={'footer-columns'}>
                        <li className={'footer-column'} style={{'width': '40%'}}>
                            CAMi is a provider of cloud-based services for construction and property management.
                            CAMi helps hedge and manage risk, measure and improve culture, and protect and promote
                            the health and safety of all through the use of an advanced analytics platform.
                        </li>
                        <li className={'footer-column'} style={{'width': '30%'}}>
                            <div>
                                <strong>CAMi</strong><br/>
                                <Link to={'/company'}>Company</Link><br/>
                                <Link to={'/offices'}>Offices</Link><br/>
                                <Link to={'/press'}>Press</Link>
                            </div>
                        </li>
                        <li className={'footer-column'} style={{'width': '30%'}}>
                            <div>
                                <strong>Contact</strong><br/>
                                New York, NY<br/>
                                San Francisco, CA
                            </div>
                        </li>
                    </ul>
                </div>
            </footer>
        );
    }
}
