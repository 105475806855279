import React from 'react';
import { Route, Link, Redirect, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import TakeEvaluation from './components/TakeEvaluation';
import RunResults from './components/RunResults';
import Login from './components/Login';
import api from './api';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import Register from "./components/Register";
import Footer from './components/Footer';
import AboutCompany from './components/AboutCompany';
import Products from './components/Products';
import Welcome from './components/Welcome';
import SafetyAndHealth from './components/SafetyAndHealth';
import OnlineTrainings from './components/OnlineTrainings';
import SiteAudits from './components/SiteAudits';
import PropertyAnalytics from './components/PropertyAnalytics';
import Technology from './components/Technology';
import Culture from './components/Culture';

import './App.css';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            api.isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);

const AuthButton = withRouter(
    ({ history }) =>
        api.isAuthenticated ? (
            <Navbar.Text className="welcome">
                Welcome {api.username}! &nbsp;
                <Navbar.Link
                    onClick={() => {
                        api.logout();
                        history.push("/");
                    }}
                >
                    Sign out
                </Navbar.Link>
            </Navbar.Text>
        ) : (
            <Navbar.Text><Link to="/login" className="login">Login</Link></Navbar.Text>
        )
);

const TheFooter = withRouter(
    ({ location }) =>
        location.pathname === '/login' ? <br/> : <Footer/>
);

class Header extends React.Component {
    render() {
        return (
            <header>
                <Navbar id={'top-navbar'} className={'navbar-fixed-top'} fluid>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Link to={'/'}>
                                <span className={'logo-CAM-text logo-text'}>CAM</span><span
                                className={'logo-i-text logo-text'}>i</span>
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>

                            {api.roles.includes("company_admin") ? <LinkContainer to="/run/results">
                                <NavItem eventKey={1}>Run Results</NavItem>
                            </LinkContainer> : undefined}

                            {api.roles.includes("survey_taker") ? <LinkContainer to="/evaluation/take">
                                <NavItem eventKey={2}>Take Evaluation</NavItem>
                            </LinkContainer> : undefined}

                            <AuthButton/>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
        );
    }
}

export default class App extends React.Component {
    render() {
        return (
            <div className={"container-fluid"}>

                <div className={'main-container'}>
                    <Header/>
                    <main>
                        <Route exact path='/' component={Welcome}/>
                        <Route exact path='/company' component={AboutCompany}/>
                        <Route exact path='/products' component={Products}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/register" component={Register}/>
                        <Route exact path={'/safety-and-health'} component={SafetyAndHealth}/>
                        <Route exact path={'/online-trainings'} component={OnlineTrainings}/>
                        <Route exact path={'/site-audits'} component={SiteAudits}/>
                        <Route exact path={'/property-analytics'} component={PropertyAnalytics}/>
                        <Route exact path={'/technology'} component={Technology}/>
                        <Route exact path={'/culture'} component={Culture}/>
                        <PrivateRoute exact path="/run/results" component={RunResults}/>
                        <PrivateRoute exact path="/evaluation/take" component={TakeEvaluation}/>
                    </main>
                </div>
            </div>
        );
    }
}
