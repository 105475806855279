import moment from 'moment';

import api from '.';

export default class {
    getRun(runId) {
        return api.requestGet(`run?runId=${runId}`)
            .then(ret=> {
                ret.openTime = moment(ret.openTime * 1000);
                ret.closeTime = moment(ret.closeTime * 1000);
                return ret;
            });
    }

    getResults(runId) {
        return api.requestGet(`run/results?runId=${runId}`);
    }

    getRuns() {
        return api.requestGet(`run/list`);
    }
}