import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { FormGroup, FormControl, Button, Alert, Glyphicon } from 'react-bootstrap';

import api from '../api';

class Login extends Component {
    state = {
        redirectToReferrer: false,
        username: '',
        password: '',
        error: false
    };

    login = (e) => {
        api.login(this.state.username, this.state.password).then((s) => {
            if(s) {
                this.setState({ redirectToReferrer: true, error: false });
            } else {
                this.setState({ error: true });
            }
        });
        e.preventDefault();
    };

    handleChange = () => {
        this.setState({
            username: this.usernameInput.value,
            password: this.passwordInput.value,
        })
    };
    renderError = () => {
        return this.state.error ? <Alert bsStyle={'danger'}>
            <Glyphicon glyph="exclamation-sign" />
            Invalid username or password
        </Alert> : null;
    };

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/" } };
        const { redirectToReferrer } = this.state;

        if (redirectToReferrer) {
            return <Redirect to={from} />;
        }

        return (
            <div style={{'width': '40%', 'margin': '0 auto'}}>
                <p>You must log in to view the page at {from.pathname}.
                   If you don't have an account you can <Link to="/register">register here</Link>.</p>
                <form>
                    {this.renderError()}
                    <FormGroup
                        controlId="usernameControl"
                    >
                        <FormControl
                            type="text"
                            value={this.state.username}
                            placeholder="Username"
                            onChange={this.handleChange}
                            inputRef={node => this.usernameInput = node}
                        />
                    </FormGroup>
                    <FormGroup
                        controlId="passwordControl"
                    >
                        <FormControl
                            type="password"
                            value={this.state.password}
                            placeholder="Password"
                            onChange={this.handleChange}
                            inputRef={node => this.passwordInput = node}
                        />
                    </FormGroup>
                    <Button type="submit" onClick={this.login}>Submit</Button>
                </form>
            </div>
        );
    }
}

export default Login;
