import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import './Welcome.css';
import { LinkContainer } from 'react-router-bootstrap';

export default class Welcome extends Component {
    render() {
        return (
            <div className={'welcome-div'}>
                <div class="center">
                    <div>
                        <h1> AI Built for Construction </h1>
                    </div>
                </div>
                <div>
                        <h2> Interested? Contact Us: mpresutti2018(at)gmail(dot)com </h2>
                </div>
            </div>
        );
    }
}
