import React, { Component } from 'react';

import './ProgressCircle.css';

class ProgressCircle extends Component {


  dashOffset () {
    const {
      valueNow,
      valueMax,
      radius
    } = this.props;

    // send the stroke-dashoffset to the meter circle, checking
    // to make sure current value doesn't exceed max value
    if (valueNow < valueMax) {
      const circumference = 2* Math.PI * radius;
      // figure out how much offset to give the stroke to show the % complete
      return circumference - ((valueNow / valueMax) * circumference);
    } else {
      return 0;
    }
  }

  render() {
    const {
      formatDisplayedValue,
      valueNow,
      valueMax,
      radius,
      color
    } = this.props;

    const value = (typeof formatDisplayedValue === 'function') && formatDisplayedValue(valueNow, valueMax);
    const numDigits = value.props.children.length;


    const trackStyle = {
      transformOrigin: radius * 1.5,
    };

    const circleStyle = {
      width: radius * 3,
      height: radius * 3,
    };

    const style = {
      stroke: color ? color : "#ff7900",
      strokeDashoffset: `${this.dashOffset()}`,
      strokeDasharray: `${radius * Math.PI * 2}`
    };

    return (<div
      className="progress-root"
      role="progressbar"
      style={circleStyle}
    >
      { value && <span className="progress-center"><span className="progress-value" style={{
        fontSize: `${radius * 1.5 / numDigits}px`}}>
        {value}
        </span></span> }
      <svg
        className="progress-circle"
        role="presentation"
        focusable="false"
        style={circleStyle}
      >
        <circle
          className="progress-shadow"
          role="presentation"
          cx="50%"
          cy="50%"
          r={radius}
        />
        <circle
          style={trackStyle}
          className="progress-track"
          role="presentation"
          cx="50%"
          cy="50%"
          r={radius}
        />
        <circle
          className="progress-meter"
          role="presentation"
          style={style}
          cx="50%"
          cy="50%"
          r={radius}
        />
      </svg>
    </div>);
  }
}

export default ProgressCircle;
